import React, { useState } from "react";
import { useCookie } from "utils/useCookie";
import { PlayerConsent, ConsentContext } from "./ConsentContext";

const hostname = window.location.hostname ?? "redgiant.com";
const domain = hostname.split(".").slice(-2).join(".");

interface ConsentProviderProps {
    children: React.ReactNode;
}

function ConsentProvider({ children }: ConsentProviderProps) {
    console.log(">> ConsentProvider: rendering");

    const [necessary, setNecessary] = useCookie<boolean>("p-consent-necessary", false);
    const [analytics, setAnalytics] = useCookie<boolean>("p-consent-analytics", false);
    const [personal,  setPersonal ] = useCookie<boolean>("p-consent-personal",  false);
    const [marketing, setMarketing] = useCookie<boolean>("p-consent-marketing", false);

    // The user has updated his choose
    const [updated, setUpdated] = useState<boolean>(false);

    const setConsent = (newConsent: PlayerConsent) => {
        console.log(">> ConsentProvider: Setting new consent which is");
        console.log(JSON.stringify(newConsent));
        console.log(">> ConsentProvider: Old consent is");
        console.log(JSON.stringify(consent));

        if (!newConsent.necessary) {
            throw new Error("You can't omit necessary consent");
        }

        setNecessary(newConsent.necessary, { days: 90, domain: domain });
        setAnalytics(newConsent.analytics, { days: 90, domain: domain });
        setPersonal (newConsent.personal,  { days: 90, domain: domain });
        setMarketing(newConsent.marketing, { days: 90, domain: domain });

        // The user has updated his choose
        setUpdated(true);
    };

    let consent: PlayerConsent = {
        necessary, analytics, personal, marketing
    };

    console.log(">> ConsentProvider: the consent is", JSON.stringify(consent) );

    return (
        <ConsentContext.Provider value={{ consent, updated, setConsent }}>
            {children}
        </ConsentContext.Provider>
    );
}

export default ConsentProvider;

// Libraries to work with browser cookies
// - [[https://www.npmjs.com/package/react-use-cookie]]
// - [[https://www.npmjs.com/package/use-cookie-state]]