import Game from "../components/main/Game";
import Roadmap from "../components/main/Roadmap";
import Faqs from "../components/main/Faqs";

function Overview() {
    return (
        <div className="main-content">
            <Game />
            <Roadmap />
            <Faqs />
        </div>
    );
};

export default Overview;
