import Icon from '../../assets/images/landing/arrow.svg';

export interface ItemData {
  type: string;
  current: string;
  max: number;
}

type BaseData = {
  data: ItemData[];
  type: string;
};

const MonkeyStats = ({ data, type }: BaseData) => {
  const title = (item: ItemData) => {
    return parseInt(item?.current ?? '0') > 8
      ? `Current ${item.current}`
      : item.current?.toString();
  };

  return (
    <div className="lp-accordion-item-wrap">
      <div className="lp-accordion-top">
        <div className="lp-accordion-top-name">Stats</div>
        <div className="arrow-wrap">
          <img src={Icon} alt="" />
        </div>
      </div>
      <div className="lp-accordion-content">
        {data.map((item) => {
          return (
            <div className={`lp-accordion-item ${type}`} key={item.type}>
              <span className="lp-accordion-item-title">{item.type}</span>
              <div className="lp-card-row">
                <div
                  className={`lp-card-row-current ${type}`}
                  title={title(item)}
                  style={{ width: `calc(${item.current}% - 26px)`, opacity: 1 }}
                >
                  {/* {parseInt(item?.current ?? '0') > 8 ? (
                    <span className="row-title">Current</span>
                  ) : (
                    <></>
                  )} */}
                  <span className="row-value">{item.current}</span>
                </div>
                <div
                  className={`lp-card-row-max ${type}`}
                  title={`Max ${item.max}`}
                  style={{ width: `calc(${item.max}% - 26px)`, opacity: 1 }}
                >
                  <span className="row-title">Max</span>
                  <span className="row-value">{item.max}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MonkeyStats;
