import CardInfo from "../components/landing/CardInfo";
import CardSlider from "../components/landing/CardSlider";
import CardTop from "../components/landing/CardTop";
import MonkeyStats from "../components/landing/MonkeyStats";
import MonkeyAppearance from "../components/landing/MonkeyAppearance";

import Question from "../assets/images/landing/card-info/question.svg";

import { useCallback, useEffect, useMemo, useState } from "react";
import MonkeyTokenDetails from "../components/landing/MonkeyTokenDetails";
import TooltipRight from "../components/landing/TooltipRight";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { Monkey } from "../models/monkey";
import { MonkeyLeagueService } from "../services/monkey-league-service";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import Loader from "../components/Loader";
import TooltipBottom from "../components/landing/TooltipBottom";

const TeamCard = () => {
  const { publicKey } = useWallet();
  const { connection } = useConnection();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [monkey, setMonkey] = useState<Monkey>();
  const [active, setActive] = useState(1);
  const [activeCardInfo, setActiveCardInfo] = useState("");
  const [pageType, setPageType] = useState<string | undefined>();
  const [typeIcon, setTypeIcon] = useState<string | undefined>();

  function randomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min) + min);
  }

  const types = ["goalkeeper", "striker", "midfielder", "defender"];

  const { id } = useParams();

  const loadMonkeys = useCallback(async () => {
    if (publicKey) {
      const monkeyLeagueService = new MonkeyLeagueService(connection);

      const _monkey = await monkeyLeagueService.getMonkey(publicKey, id ?? "");

      const type = await _monkey?.calcType();

      setPageType(type);
      const icon = require(`../assets/images/team/${type}.svg`);
      setTypeIcon(icon.default)

      setMonkey(_monkey);
      setLoading(false);
    }
  }, [connection, id, publicKey]);

  useEffect(() => {
    loadMonkeys();
  }, [loadMonkeys]);

  useEffect(() => {
    if (!publicKey) navigate("/connect");
  }, [publicKey, navigate]);

  //active accordion row
  const activeHandler = (index: number) => {
    // if (index === active) {
    // setActive(1);
    // } else {
    setActive(index);
    // }
  };

  //hover card info handler
  const activeCardInfoHandler = (e: any, id: string) => {
    setActiveCardInfo(id);
  };

  const monkeyData = {
    max: {
      icon: "potential",
      name: "Max potential",
      value: "Striker",
      tooltip: "This indicates the position your Monkey will perform best in once its' stats reach Max Potential"
    },
    perks:
      "These are perks that increase your Monkey's abilities in various actions of match-play",
    scoreText:
      "This is an appearance uniqueness score. Higher scores increase the Monkey's ability in certain game actions.",
  };

  const showLoader = () => (
    <div className="container">
      <Loader />
    </div>
  );

  const showPage = () => {
    console.log(monkey);
    return (
      <div className="container team-card">
        <div className="lp-team-items-wrap">
          <div className="lp-team-back">
            <Link to={"/drop/team"} className="lp-team-link">
              Back To My Team
            </Link>
          </div>
          <div className="lp-team-card-wrap">
            <div className="lp-team-item images">
              <div className="lp-team-card-images">
                <CardSlider images={monkey!.galleryImages} />
              </div>
            </div>
            <div className="lp-team-item">
              <div className="lp-team-card-content">
                <CardTop monkey={monkey} />

                <div className="lp-team-card-title">
                  Perks
                  <span className="question-wrap">
                    <span className="question">
                      <img src={Question} alt="" />
                    </span>
                    <TooltipRight text={monkeyData.perks} />
                  </span>
                </div>

                <div className="lp-card-icons">
                  <div className="lp-card-info-wrap">
                    {monkey?.info.map((item) => {
                      return (
                        <CardInfo
                          key={item.id}
                          item={item}
                          active={activeCardInfo}
                          hoverHandler={activeCardInfoHandler}
                        />
                      );
                    })}
                  </div>
                  <div className="lp-card-icon-max">
                    <img alt='' className="lp-icon-max-image" src={typeIcon} />
                    <span className="max-image-question-wrap">
                      <span className="question">
                        <img src={Question} alt="" />
                      </span>
                      <TooltipBottom text={monkeyData.max.tooltip} />
                    </span>
                  </div>
                </div>

                <div className="lp-card-accordion-wrap">
                  <div className="lp-accordion-wrap">
                    <div
                      className={`lp-accordion-inner-wrap ${
                        active === 1 ? "active" : ""
                      }`}
                      onClick={() => activeHandler(1)}
                    >
                      <MonkeyStats type={pageType ?? ''} data={monkey?.stats ?? []} />
                    </div>
                    <div
                      className={`lp-accordion-inner-wrap ${
                        active === 2 ? "active" : ""
                      }`}
                      onClick={() => activeHandler(2)}
                    >
                      <MonkeyAppearance
                        type={pageType ?? ''}
                        data={monkey?.appearance ?? []}
                        score={monkey?.alphaScore}
                        scoreText={monkeyData.scoreText}
                      />
                    </div>
                    <div
                      className={`lp-accordion-inner-wrap ${
                        active === 3 ? "active" : ""
                      }`}
                      onClick={() => activeHandler(3)}
                    >
                      <MonkeyTokenDetails details={monkey?.details ?? []} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return monkey?.monkeyData ? showPage() : showLoader();
};

export default TeamCard;
