import { ChangeEvent } from "react";

type Props = {
    checked: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
};

function Checkbox({ checked, onChange, disabled }: Props)
{
    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.checked);
    };

    return (
        <label className="swap swap-rotate">
            {/* this hidden checkbox controls the state */}
            <input
                type="checkbox"
                defaultChecked={checked}
                onChange={onInputChange}
                disabled={disabled}
            />

            {/* on icon */}
            <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
                className="swap-on fill-current w-10 h-10"
            >
                <g fill="none" fillRule="evenodd">
                    <rect fill="#FC168F" width="48" height="48" rx="24" />
                    <path
                        d="M33.235 20.432 22.63 31.039l-.102-.104-.102.104L15 23.614l3.182-3.182 4.345 4.344 7.526-7.526z"
                        fill="#FFEB00"
                    />
                </g>
            </svg>

            {/* off icon */}
            <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                xmlns="http://www.w3.org/2000/svg"
                className="swap-off fill-current w-10 h-10"
            >
                <g fill="none" fillRule="evenodd">
                    <rect
                        width="48"
                        height="48"
                        rx="24"
                        stroke="#FFF"
                        strokeWidth="1.5"
                        transform="translate(1 1)"
                    />
                    <path
                        d="M34.235 21.432 23.63 32.039l-.102-.104-.102.104L16 24.614l3.182-3.182 4.345 4.344 7.526-7.526z"
                        fill="#605BBA"
                    />
                </g>
            </svg>
        </label>
    );
}

export default Checkbox;
