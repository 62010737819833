import { useWallet } from "@solana/wallet-adapter-react";
import {
    WalletModalProvider,
    WalletDisconnectButton,
    WalletMultiButton
} from "@solana/wallet-adapter-react-ui";

function WalletButton()
{
    const { connected, publicKey } = useWallet();

    const generatePublicKeyShorct = (key: string) =>
        `${key.substring(0, 4)}...${key.substring(key.length - 4, key.length)}`;

    return (
        <WalletModalProvider>
            <WalletMultiButton
                startIcon={<span className="icon-wallet"></span>}
                className="btn btn-bg-yellow"
            >
                <span className="btn-text">
                    {connected && publicKey
                        ? generatePublicKeyShorct(publicKey.toString() )
                        : "Connect Wallet"
                    }
                </span>
            </WalletMultiButton>
            {/* <Button onClick={generateSignedTransaction}>Sign Transaction</Button> */}
        </WalletModalProvider>
    );
};

export default WalletButton;
