import Icon from '../../assets/images/landing/gen.svg';
import { Monkey } from '../../models/monkey';

type BaseCardTop = {
  monkey?: Monkey;
};

const CardTop = ({ monkey }: BaseCardTop) => {
  return (
    <div className="lp-card-top">
      <div className="lp-card-name-wrap">
        <div className="lp-card-name">
          <span>{monkey?.name}</span>
        </div>
        <div className="lp-card-id">I.D. #{monkey?.id}</div>
      </div>
      <div className="lp-card-top-details">
        <div className="lp-top-details-item">
          <img src={Icon} alt="Gen" />
          <div className="lp-details-name">{monkey?.collection}</div>
        </div>
        <div className="lp-top-details-birthday">Birthday: <span>{monkey?.birthday}</span></div>
      </div>
    </div>
  );
};

export default CardTop;
