import { CSSProperties } from "react";
import BannerHeadlight from "../displays/BannerHeadlight";
import LoadingBall from "../displays/loading/LoadingBall";

export type AreaButtonProps = {
    label: string;
    sublabel?: string;
    fontSize?: string;
    size?: AreaButtonSize;
    to?: string;
    onClick?: () => void;
    loading?: boolean;
    bgImg?: string;
};

export enum AreaButtonSize {
    GROW = "grow min-h-[5rem]",
    GROW_SHORT = "grow min-h-[3rem]",
    TINY = "w-[6rem] h-[2.5rem]",
    SMALL = "w-[15rem] h-[3rem]",
    MEDIUM = "w-[18rem] h-[5rem]",
    FOOTER = "w-[7rem] h-[2.5rem]"
}

export default function AreaButton({
    label,
    sublabel,
    fontSize,
    size,
    to,
    onClick,
    loading,
    bgImg
}: AreaButtonProps) {
    fontSize = fontSize ? fontSize : "text-5xl";
    const handlers = to ? { href: to } : onClick ? { onClick: onClick } : {};
    const areaSize: string = size ? size : AreaButtonSize.GROW;
    const bgCSS: CSSProperties = bgImg ? {
        backgroundImage: `url(${bgImg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right top",
    } : {};

    return (
        <a
            className={`area-button select-none ${areaSize} ${fontSize}`}
            {...handlers}
            style={bgCSS}
        >
            <BannerHeadlight />
            { loading && <LoadingBall /> }
            { !loading && (
                <p className={`m-auto leading-[2rem] pb-1`}>
                    { label }
                    { sublabel && (
                        <>
                            <br />
                            <span className="text-3xl">{sublabel}</span>
                        </>
                    )}
                </p>
            )}
        </a>
    );
}
