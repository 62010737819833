import loadBallImg from "./img/CTA_Loader_Ball.png";
import loadSpinImg from "./img/CTA_Loader_Spin.png";
import loadDotsImg from "./img/CTA_Loader_Dots.png";

function LoadingBall() {
    return (
        <div className="relative w-full h-full">
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <div className="relative w-[60px] h-[60px] overflow-visible">
                    <img className="absolute rotate" src={loadSpinImg} alt="" />
                    <img
                        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                        src={loadBallImg}
                        alt=""
                    />
                </div>
            </div>
            <img
                className="absolute bottom-5 left-1/2 -translate-x-1/2"
                src={loadDotsImg}
                alt=""
            />
        </div>
    );
}

export default LoadingBall;
