import { useState, useRef } from "react";

import RoadmapItem from "./RoadmapItem";
import Title from "../Title";

import Arrow from "../../assets/images/arrow.png";

const Roadmap = () => {
  const [show, setShow] = useState(false);
  const moreRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const clickHandler = () => {
    if (show) {
      containerRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    } else {
      setTimeout(() => {
        containerRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 1);
    }

    setShow(!show);
  };

  const title = {
    text: "Roadmap",
    color: "#E2C600",
  };

  const list = [
    {
      id: 1,
      title: "October",
      text: "MonkeyBucks $MBS Pre-Sale",
    },
    {
      id: 2,
      title: "November",
      text: "IDO Begins",
    },
    {
      id: 3,
      title: "December",
      text: "MonkeyBucks $MBS Token Launch",
    },
    {
      id: 4,
      title: "January",
      text: "Monkey NFT Drop",
      active: true,
    },
    {
      id: 5,
      title: "Q1 2022",
      text: "Pioneers Gear-Up Camp",
    },
    {
      id: 6,
      title: "Q1 2022",
      text: "In-Game Store Launch",
    },
    {
      id: 7,
      title: "Q1 2022",
      text: "Breeding Season",
    },
    {
      id: 8,
      title: "Q2 2022",
      text: "Player vs Environment Game Mode",
    },
    {
      id: 9,
      title: "Q3 2022",
      text: "Treasury Launch",
    },
    {
      id: 10,
      title: "Q3 2022",
      text: "Player vs Player Game Mode",
    },
    {
      id: 11,
      title: "Q4 2022",
      text: "Stadium NFT Drop",
    },
  ];

  return (
    <div
      id="roadmap"
      className={`roadmap ${show ? "active" : ""}`}
      ref={containerRef}
    >
      <div className="roadmap-hidden"></div>
      <div className="roadmap-shown"></div>
      <div className="container">
        <Title item={title} />
        <div className={`roadmap-list ${show ? "active" : ""}`}>
          {list.map((item) => {
            if (!show && item.id < 5)
              return <RoadmapItem key={item.id} item={item} />;
            if (show) return <RoadmapItem key={item.id} item={item} />;
            else return false;
          })}
        </div>
        <div ref={moreRef} className={`more ${show ? "active" : ""}`}>
          <img onClick={clickHandler} src={Arrow} alt="show more" />
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
