import { useMemo, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Navigate } from "react-router";

import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import LegalPack from "./pages/legalPack/LegalPack";
import AuditDisclaimer from "./pages/AuditDisclaimer";
import Overview from "./pages/Overview";
import Connect from "./pages/Connect";
import TeamCard from "./pages/TeamCard";
import Team from "./pages/Team";

// Analytics
import AnalyticsProvider from "./components/analytics/AnalyticsProvider";

// GDPR Cookie Consent
import ConsentProvider from "./components/gdpr/ConsentProvider";
import GetPlayerConsent from "./components/gdpr/GetPlayerConsent";

// Solana Wallets
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { getPhantomWallet, getSolflareWallet, getSolletExtensionWallet } from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";

function App()
{
    (window as any).version = '1.0.2';
    console.log(`>> Invoked - App`);
    
    const { state } = useLocation();
    const network = (process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork) ?? WalletAdapterNetwork.Devnet;

    const clusterApiInternal = (network: WalletAdapterNetwork) => {
        if (network === WalletAdapterNetwork.Mainnet) {
            return 'https://ssc-dao.genesysgo.net/';
        } else {
            return clusterApiUrl(network);
        }
    }

    const endpoint = useMemo(() => clusterApiInternal(network), [network]);
    const wallets = useMemo(
        () => [
            getPhantomWallet(),
            getSolflareWallet(),
            getSolletExtensionWallet({ network }),
        ],
        [network]
    );

    return (
        <ConsentProvider>
            <AnalyticsProvider>
                <ConnectionProvider endpoint={endpoint}>
                    <WalletProvider wallets={wallets} autoConnect>
                        <GetPlayerConsent />
                        <Header />
                        <Routes>
                            <Route path="/" element={<Navigate replace to="/connect" />} />
                            <Route path="overview/*" element={<Overview />} />
                            <Route path="legal-pack/*" element={<LegalPack />} />
                            <Route path="audit-disclaimer" element={<AuditDisclaimer />} />
                            <Route path="connect" element={<Connect redirect={(state as any)?.redirect} />} />
                            <Route path="team" element={<Team />} />
                            <Route path="team/:id" element={<TeamCard />} />
                            <Route path="*" element={<Navigate replace to="/" />} />
                        </Routes>
                        <Footer />
                    </WalletProvider>
                </ConnectionProvider>
            </AnalyticsProvider>
        </ConsentProvider>
    );
}

export default App;
