import { useState, useRef, useEffect } from "react";

import Title from "../Title";
import FaqsItem from "./FaqsItem";

import Image from "../../assets/images/faqs-bg.png";

const Faqs = () => {
  const [active, setActive] = useState("1");
  const width = window.innerWidth;
  const [value, setValue] = useState(0);
  const faqsRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);

  const imageScroll = () => {
    let position = window.pageYOffset;
    let faqsHeight = faqsRef.current?.clientHeight;
    let imageHeight = imageRef.current?.clientHeight;
    let offsetTop = faqsRef.current?.offsetTop;
    if (offsetTop && faqsHeight && imageHeight) {
      let scrollingHeight = faqsHeight - imageHeight * 1.7;
      let diff = position - offsetTop;
      if (diff > 50) {
        if (diff < scrollingHeight) {
          setValue(diff);
        }
      } else {
        setValue(0);
      }
    }
  };

  const activeHandler = (id: string) => {
    if (id === active) {
      setActive("0");
    } else {
      setActive(id);
    }
  };
  const title = {
    text: "FAQS",
    color: "#fff",
  };

  const list = [
    {
      id: "1",
      title: "What is MonkeyLeague?",
      content: [
        "MonkeyLeague is a quick, turn-based, Play-to-Earn, arcade, soccer game offering a high-production-value gaming experience.",
        "Matches are played between two teams, each with 4 Monkeys playing the positions of Scorer, Defender, Midfielder and Goalkeeper. With 6 rounds of play per half, the first team to 3 goals wins.",
        "Back at HQ, boost your Monkeys’ abilities and in-game performance by caring for and training them, as well as increasing their morale.",
        "Stadium owners are rewarded for matches they host, while other players can be spectators and influence the gameplay by cheering for a winning team to increase their gameplay.",
        "When a match ends, the in-game currency token, MonkeyBucks ($MBS), is split between the winning team, the Stadium owner, and the spectators who rooted for the winning team.",
      ],
    },
    {
      id: "2",
      title: "How can I participate in the game and earn income?",
      content: [
        "There will be multiple ways for players to participate in the game economy for both Active and Passive players.",
        "Players can either own Monkeys, build a team and win matches, own Stadiums and host matches, or be a match Spectator and earn by cheering for the winning team.",
        "Once a match ends, the winning team, Stadium owner, and the Spectators that rooted for the winning team receive a split the winnings of the match in MonkeyBucks $MBS",
      ],
    },
    {
      id: "3",
      title: "How do I buy a Monkey NFT?",
      content: [
        "You can buy a Monkey NFT by participating in the NFT drop happening this December. Specific details coming soon!",
        "The sale will include a limited quantity of Monkeys at a fixed price of 2 SOL. The rarity of the Monkey that each purchaser receives will be set randomly, and the Monkeys will be sent to the buyers immediately once the sale is concluded.",
      ],
    },
    {
      id: "4",
      title: "How do I buy a Stadium NFT?",
      content: [
        "Later this year, we will offer a limited supply of Stadiums in an exclusive NFT drop. For more details please join our Discord and Telegram channels and stay tuned to our announcements!",
      ],
    },
    {
      id: "5",
      title: "Can I improve my Monkey skills?",
      content: [
        "Each Monkey NFT has a unique DNA composed of Appearance, Trainable Abilities, and Special Elements. Monkeys are born with an initial and a maximum potential score for each trait, a rare Monkey will have a high initial score for any of his traits and/or a high potential score.",
        "Players can customize their Monkeys with uniforms, jewelry, boots, and other game assets that serve as boosters to their skills. Meaning, when Monkeys consume in-game items, they improve their skills.",
      ],
    },
    {
      id: "6",
      title: "Where can I learn more about the MonkeyLeague game system?",
      content: [
        "For more information about the game system, the Monkey NFTs, the in-game currency ($MBS) and the governance token ($SCORE) please read our whitepaper",
        "You can also check our socials below and approach our Team via Telegram or Discord with any questions you might have.",
      ],
    },
    {
      id: "7",
      title: "What is MonkeyBucks ($MBS)?",
      content: [
        "MonkeyBucks ($MBS) is the in-game currency powering the game economy.",
        "Players can get $MBS by buying it on DEXs, opening loot boxes, completing missions, winning matches, watching games as spectators or hosting games in their stadiums.",
        "Players can spend $MBS to breed new Monkeys and to buy consumable game items in the game’s store.",
      ],
    },
    {
      id: "8",
      title: "I need help?!",
      content: [
        "If you’re having difficulty, please join our Discord server, or approach our team on Telegram via our Official Group.",
      ],
    },
  ];

  useEffect(() => {
    if (width > 1023) {
      window.addEventListener("scroll", imageScroll);

      return () => window.removeEventListener("scroll", imageScroll);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div id="faqs" className="faqs" ref={faqsRef}>
      <div className="faqs-container container">
        <div id="faqs-media" className="faqs-inner faqs-media">
          <div
            id="faqs-media"
            className="faqs-media-img"
            ref={imageRef}
            style={{ transform: `translateY(${value}px)` }}
          >
            <img src={Image} alt="" />
            <div className="mobile-line"></div>
          </div>
        </div>
        <div className="faqs-inner faqs-content">
          <Title item={title} />
          <div className="accordion-wrap">
            {list.map((item) => {
              return (
                <FaqsItem
                  key={item.id}
                  item={item}
                  active={active}
                  clickHandler={activeHandler}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
