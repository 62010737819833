import { Routes, Route, Outlet, Link } from "react-router-dom";
import { TermsOfUse, Disclaimers, PrivacyPolicy, AML } from "./pagesData";

const LegalPack = function()
{
    return (
        <Routes>
            <Route path="/" element={
                <div className="legal-pack">
                    <h1>Legal Pack</h1>
                    <div className="legal-pack-links">
                        <Link to="/legal-pack/terms-of-use">Terms of Use</Link>
                        <Link to="/legal-pack/disclaimers">Disclaimers</Link>
                        <Link to="/legal-pack/privacy-policy">Privacy Policy</Link>
                        <Link to="/legal-pack/aml">AML</Link>
                    </div>
                    <Outlet />
                </div>
            } />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/disclaimers" element={<Disclaimers />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/aml" element={<AML />} />
        </Routes>
    );
};

export default LegalPack;
