import { Monkey } from '../models/monkey';

type BaseMonkey = {
  monkey: Monkey;
};

const MonkeyCard = ({ monkey }: BaseMonkey) => {

  return (
    <div className="lp-monkey-card">
      <div className="lp-monkey-img-wrap">
        <div
          className="lp-monkey-img"
          style={{ backgroundImage: `url(${monkey.imageUrl})` }}
        ></div>
        <div className="lp-monkey-name-wrap">
          <div className="lp-monkey-name">{monkey.name}</div>
        </div>
      </div>
      <div className="lp-monkey-card-content">
        <span>{monkey.collection}</span>
        <span># {monkey.id}</span>
      </div>
    </div>
  );
};

export default MonkeyCard;
