interface IRowsGrid {
    cols?: ColsConfig;
    children: React.ReactNode;
}

// You cant build the tail wind class dynamically
// so we store class names in config
export enum ColsConfig {
    M1D2 = "grid-cols-1 md:grid-cols-2",
    M2D2 = "grid-cols-2 md:grid-cols-2",
    M2D3 = "grid-cols-2 md:grid-cols-3",
}

function ColsGrid({ children, cols }: IRowsGrid)
{
    if (!cols) {
        cols = ColsConfig.M1D2;
    }

    return (
        <div className={`grid ${cols} gap-4`}>{ children }</div>
    );
}

export default ColsGrid;
