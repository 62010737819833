import { CSSProperties } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";

import AreaButton, { AreaButtonSize } from "components/ui/buttons/AreaButton";
import ColsGrid, { ColsConfig } from "components/ui/containers/ColsGrid";
import Popup from "components/ui/modals/Popup";

import ConsentTopic, { ConsentTopics } from "./ConsentTopic";

import { PlayerConsent } from "./ConsentContext";

import bgImg from "./img/popup-bg-strips.svg";

// Privacy Policy URL
const PRIVACY_POLICY_URL = "/legal-pack/privacy-policy";

type Props = {
    isOpen: boolean;
    closeHandler: (consent?: PlayerConsent) => void;
};

const bgCss: CSSProperties = {
    backgroundImage: `url('${bgImg}'), linear-gradient(180deg, rgba(57,56,133,1) 0%, rgba(80,78,162,1) 100%)`,
    backgroundSize: "100%, 100%",
    backgroundRepeat: "no-repeat, no-repeat",
    backgroundPosition: "calc(100% + 140px) bottom, 0px 0px",
};

function ConsentSettings({ isOpen, closeHandler }: Props) {
    const consent: PlayerConsent = {
        necessary: true,
        analytics: false,
        personal:  false,
        marketing: false
    };

    const onConsentTopicChange = (id: ConsentTopics, value: boolean) => {
        switch (id) {
            case ConsentTopics.Analytics:
                consent.analytics = value;
                break;

            case ConsentTopics.Marketing:
                consent.marketing = value;
                break;

            case ConsentTopics.Personal:
                consent.personal = value;
                break;
        }
    };

    const topics = [
        <ConsentTopic
            key="nessesary"
            id={ConsentTopics.Necessary}
            title="Strictly Necessary"
            description="Cookies required to enable basic website functionality (Always Active)."
            checked={consent.necessary}
            onChange={onConsentTopicChange}
            disabled={true}
        />,
        <ConsentTopic
            key="marketing"
            id={ConsentTopics.Marketing}
            title="Marketing"
            description="Cookies used to deliver advertising that is more relevant to you and your interests."
            checked={consent.marketing}
            onChange={onConsentTopicChange}
        />,
        <ConsentTopic
            key="personal"
            id={ConsentTopics.Personal}
            title="Personalization"
            description="Cookies allowing the website to remember choices you make (such as your user name, language, or the region you are in)."
            checked={consent.personal}
            onChange={onConsentTopicChange}
        />,
        <ConsentTopic
            key="analytics"
            id={ConsentTopics.Analytics}
            title="Analytics"
            description="Cookies helping understand how this website performs, how visitors interact with the site, and whether there may be technical issues."
            checked={consent.analytics}
            onChange={onConsentTopicChange}
        />
    ];

    const onSaveSettings = () => {
        closeHandler(consent);
    };

    const onAcceptAll = () => {
        closeHandler({
            necessary: true,
            analytics: true,
            personal:  true,
            marketing: true
        });
    };

    return (
        <ReactModal
            isOpen={isOpen}
            className="modal"
            overlayClassName="overlay"
            appElement={document.getElementById("root") as HTMLElement}
        >
            <Popup onClose={() => closeHandler()} style={bgCss}>
                <div className="cookie-settings space-y-10">
                    <div className="cookie-settings-container space-y-2">
                        <p className="cookie-settings-header">Cookie Settings</p>
                        <p className="cookie-settings-text">
                            By clicking “Accept All Cookies”, you agree to the storing of
                            cookies on your device to enhance site navigation, analyze site
                            usage and assist in our marketing efforts.{" "}
                            <span>
                                <a className="cookie-settings-link" href={PRIVACY_POLICY_URL}>
                                    More info
                                </a>
                            </span>
                        </p>
                    </div>

                    <div className="cookie-settings-grid ">
                        <ColsGrid cols={ColsConfig.M1D2}>{ topics }</ColsGrid>
                    </div>
                </div>

                <div className="cookie-buttons-wrap">
                    <div className="cookie-accept-all">
                        <AreaButton
                            label="Accept All"
                            onClick={onAcceptAll}
                            size={AreaButtonSize.GROW_SHORT}
                            fontSize="text-3xl"
                        />
                    </div>
                    <a
                        className="cookie-save-settings"
                        onClick={onSaveSettings}
                    >
                        Save Settings
                    </a>
                </div>
            </Popup>
        </ReactModal>
    );
}

export default ConsentSettings;
