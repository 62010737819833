function AuditDisclaimer() {
    return (
        <div className="child-page">
            <div className="child-page-content">
                <h2>AUDIT DISCLAIMER</h2>

                <p>The MonkeyLeague token (MBS) is a fully{" "}
                <strong>standard Solana Program Library (SPL) Token</strong> and was
                created via the standard SPL Token Program
                (TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA). As such, its deployment
                is safe, simple, and very straightforward.</p>

                <p>MonkeyBucks (MBS) Token Security Audit was conducted by Kanso Labs.
                You can download the report{" "}
                <a
                    href="/MonkeyLeague-MBS-Token-Security-Audit-1.0.pdf"
                    download
                ><b>here</b></a>.</p>
            </div>
        </div>
    );
};

export default AuditDisclaimer;
