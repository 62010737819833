import { useContext, createContext } from "react";

export type PlayerConsent = {
    necessary: boolean;
    analytics: boolean;
    personal:  boolean;
    marketing: boolean;
};

type ConsentContextType = {
    consent: PlayerConsent;
    updated: boolean;
    setConsent: (consent: PlayerConsent) => void;
};

export const ConsentContext = createContext<ConsentContextType>({
    consent: {
        necessary: false,
        analytics: false,
        personal:  false,
        marketing: false
    },
    updated: false,
    setConsent: function() {
        console.warn("No consent provider");
    }
});

export function useConsent() {
    return useContext(ConsentContext);
}
