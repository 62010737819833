import { useState } from "react";
import { useConsent, PlayerConsent } from "./ConsentContext";

// Settings
import ConsentSettings from "./ConsentSettings";

// UI Components
// import BannerHeadlight from "components/ui/displays/BannerHeadlight";
import AreaButton, { AreaButtonSize } from "components/ui/buttons/AreaButton";

// Privacy Policy URL
const PRIVACY_POLICY_URL = "/legal-pack/privacy-policy";

function GetPlayerConsent() {
    const [ isSettingsOpen, setSettingsOpen ] = useState(false);
    const { consent, setConsent } = useConsent();

    const settingsSave = (newConsent?: PlayerConsent) => {
        setSettingsOpen(false);
        if (newConsent) { setConsent(newConsent); }
    };

    const acceptAllHandler = () => {
        settingsSave({
            necessary: true,
            analytics: true,
            personal:  true,
            marketing: true
        });
    };

    return (!consent.necessary) ? (
        <div className="cookie-consent">
            {/* <BannerHeadlight /> */}
            <div className="cookie-consent-container">
                <div className="consent-text-wrap">
                    <p className="consent-header">
                        We use cookies to enhance your experience
                    </p>
                    <p className="consent-text">
                        We use cookies which allow us to optimize functionality of our
                        website as well as tailor our content to your interest. If you
                        would prefer not to allow this, you can change your default
                        settings under "Cookie Settings". You consent to our cookies if
                        you continue to use our website.{" "}
                        <span>
                            <a className="consent-link" href={PRIVACY_POLICY_URL}>
                                Read more about cookies
                            </a>
                        </span>
                    </p>
                </div>
                
                <div className="consent-buttons-wrap space-y-2">
                    <AreaButton
                        label="Accept"
                        onClick={acceptAllHandler}
                        size={AreaButtonSize.SMALL}
                        fontSize="text-3xl"
                    />
                    <a
                        className="consent-button-settings select-none"
                        onClick={() => {
                            setSettingsOpen(true);
                        }}
                    >
                        Cookies Settings
                    </a>
                </div>
            </div>

            <ConsentSettings
                isOpen={isSettingsOpen}
                closeHandler={settingsSave}
            />
        </div>
    ) : null;
}

export default GetPlayerConsent;
