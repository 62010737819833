import Tooltip from './Tooltip';

type BaseCardInfo = {
  item: {
    id: string;
    name: string;
    value: string;
    details: {
      name: string;
      value: string;
    }[];
  };
  hoverHandler: (e: React.MouseEvent<HTMLDivElement>, id: string) => void;
  active: string;
};

const CardInfo = ({ item, hoverHandler, active }: BaseCardInfo) => {
  const name = item.name.toLowerCase();
  const Icon = require(`../../assets/images/landing/card-info/${name}.svg`);
  const IconActive = require(`../../assets/images/landing/card-info/${name}-active.svg`);
  return (
    <div
      className="lp-card-info-item"
      onMouseEnter={(e) => hoverHandler(e, item.id)}
      onMouseLeave={(e) => hoverHandler(e, '')}
    >
      <div className="lp-card-info-icon-wrap">
        {active === ''
          ? IconActive && (
              <img
                src={IconActive.default}
                className="lp-card-info-icon-active"
                alt={item.name}
              />
            )
          : active === item.id
          ? IconActive && (
              <img
                src={IconActive.default}
                className="lp-card-info-icon-active"
                alt={item.name}
              />
            )
          : Icon && (
              <img
                src={Icon.default}
                className="lp-card-info-icon"
                alt={item.name}
              />
            )}
      </div>
      <div className="lp-card-info-title">{item.name}</div>
      <div className="lp-card-info-value">{item.value}</div>
      <Tooltip item={item} />
    </div>
  );
};

export default CardInfo;
