type BaseText = {
  text: string;
}

const TooltipRight = ({ text }: BaseText) => {
  return (
    <div className='text-tooltip' role='tooltip'>
      {text}
    </div>
  )
}

export default TooltipRight
