import Icon from '../../assets/images/landing/arrow.svg';
import Score from '../../assets/images/landing/score.svg';
import Question from '../../assets/images/landing/card-info/question.svg';
import TooltipBottom from './TooltipBottom';

type BaseData = {
  data: {
    name: string;
    value: string;
  }[];
  score: string;
  scoreText: string;
  type: string;
};

const MonkeyAppearance = ({ type, data, score, scoreText }: BaseData) => {
  return (
    <div className="lp-accordion-item-wrap">
      <div className="lp-accordion-top">
        <div className="lp-accordion-top-name">Appearance</div>
        <div className="lp-accordion-top-info">
          <span className="lp-accordion-top-icon">
            <img src={Score} alt="" />
          </span>
          <span>Alpha Score</span>
          <span className={`lp-accordion-top-value ${type}`}>{score}</span>
          <span className="question-wrap">
            <span className="question">
              <img src={Question} alt="" />
            </span>
            <TooltipBottom text={scoreText} />
          </span>
          <span className="arrow-wrap">
            <img src={Icon} alt="" />
          </span>
        </div>
      </div>
      <div className="lp-accordion-content lp-accordion-columns">
        {data.map((item, index) => {
          let name = item.name.toLowerCase().replace(/ /g, '-');
          const Icon = require(`../../assets/images/landing/appearance/${name}.svg`);
          return (
            <div key={index} className="lp-accordion-column">
              <div className="left">
                <span className="lp-accordion-column-icon">
                  <img src={Icon.default} alt={name} />
                </span>
                <span className="lp-accordion-column-name">{item.name}</span>
              </div>
              <div>
                <span className={`lp-accordion-column-value ${type}`}>
                  {item.value}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MonkeyAppearance;
