import { Fragment } from "react";

import Medium   from "assets/images/medium.png";
import Twitter  from "assets/images/twitter.svg";
import Telegram from "assets/images/telegram.svg";
import Discord  from "assets/images/discord.svg";

const social = [
    {
        id: 1,
        title: "Medium",
        link: process.env.REACT_APP_MEDIUM ?? "",
        img: Medium
    },
    {
        id: 2,
        title: "Twitter",
        link: process.env.REACT_APP_TWITTER ?? "",
        img: Twitter
    },
    {
        id: 3,
        title: "Telegram",
        link: process.env.REACT_APP_TELEGRAM ?? "",
        img: Telegram
    },
    {
        id: 4,
        title: "Discord",
        link: process.env.REACT_APP_DISCORD ?? "",
        img: Discord
    }
];

type SocialProps = {
    item: {
        title: string;
        link: string;
        img: string;
    }
}

function Social({ item } : SocialProps) {
    return (
        <a href={item.link} target='_blank' rel='noreferrer' className='social-link'>
            <img src={item.img} alt={item.title} />
        </a>
    );
}

function SocialLinks() {
    return (
        <Fragment>
            { social.map((item) => <Social key={item.id} item={item} />) }
        </Fragment>
    );
}

type SocialLinksProps = {
    showInMenu: boolean;
    showMobile: boolean;
}

function HeaderSocialLinks({ showInMenu, showMobile } : SocialLinksProps) {
    return (
        <div className={`social ${showInMenu ? "monkey-header-social" : ""} ${showMobile ? "mobile-active" : ""}`}>
            { social.map((item) => <Social key={item.id} item={item} />) }
        </div>
    );
}

export { Social, SocialLinks, HeaderSocialLinks };
