import Checkbox from "components/ui/buttons/Checkbox";

export enum ConsentTopics {
    Necessary = "Necessary",
    Analytics = "Analytics",
    Marketing = "Marketing",
    Personal  = "Personal",
};

type Props = {
    id: ConsentTopics;
    checked: boolean;
    title: string;
    description: string;
    onChange: (id: ConsentTopics, value: boolean) => void;
    disabled?: boolean;
};

function ConsentTopic({
    id,
    checked,
    title,
    description,
    onChange,
    disabled,
}: Props) {
    const onCheckboxChange = (checkedStatus: boolean) => {
        onChange(id, checkedStatus);
    };

    return (
        <div key={"topic-" + id} className="consent-topic space-x-4">
            <Checkbox
                checked={checked}
                onChange={onCheckboxChange}
                disabled={disabled}
            />
            <div className="consent-topic-container">
                <p className="consent-topic-title">{title}</p>
                <p className="consent-topic-desc">{description}</p>
            </div>
        </div>
    );
}

export default ConsentTopic;
