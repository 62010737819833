import { CSSProperties } from "react";
import PopupCloseButton from "../buttons/PopupCloseButton";

type Props = {
    onClose: () => void;
    backgroundImage?: string;
    style?: CSSProperties;
    children: React.ReactNode;
};

export default function Popup({
    onClose,
    children,
    backgroundImage,
    style
}: Props) {
    if (!style && backgroundImage) {
        style = {
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "right bottom",
        };
    }
    else if (!style && !backgroundImage) {
        style = {
            backgroundColor: "rgb(30 28 86 / 1)"
        };
    }

    return (
        <div className="modal-popup">
            <PopupCloseButton closeHandler={onClose} />
            <div className="modal-popup-container space-y-4" style={style}>
                { children }
            </div>
        </div>
    );
}
