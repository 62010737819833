import loader from "../assets/images/loader.png";

const Loader = ({ loadingText = "Loading" }) => (
  <div className="loader">
    <img src={loader} alt="loader" />
    <div className="text-wrapper">
      <p>{loadingText}</p>
      <div className="dot-flashing"></div>
    </div>
  </div>
);

export default Loader;
