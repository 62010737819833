import { programs } from "@metaplex/js";
import { AccountInfo, ParsedAccountData, PublicKey } from "@solana/web3.js";
import axios from "axios";
import moment from "moment";
import XSLX from 'xlsx';
import { Deferred } from "./deferred";
import { PositionXLSX } from "./position.xlsx";
import sleep from 'await-sleep'
const XLSX_CALC = require('xlsx-calc');
export interface Trait {
  name: string;
  value: string;
}
export interface Attribute {
  trait_type: string;
  value: string;
}

export interface CollectionData {
  name: string;
}

export interface FileDescriptor {
  uri: string;
  type: string;
}
export interface MonkeyData {
  id: string;
  description: string;
  external_url: string;
  image: string;
  name: string;
  attributes: Attribute[];
  collection: CollectionData;
  properties: {
    files: FileDescriptor[]
  }
}

export interface MonkeyAttributes {
  [key: string]: any;
}

export class Monkey {
  publicKey: PublicKey;
  account?: AccountInfo<ParsedAccountData>;
  metadata: programs.metadata.Metadata;
  monkeyData?: MonkeyData;
  attributes: MonkeyAttributes;
  mint: string;
  ready: Deferred<boolean> = new Deferred();

  constructor(
    publicKey: PublicKey,
    metadata: programs.metadata.Metadata,
    account?: AccountInfo<ParsedAccountData>,
    mint?: string
  ) {
    this.publicKey = publicKey;
    this.account = account;
    this.metadata = metadata;
    this.mint = account?.data.parsed.info.mint.toString() ?? mint;
    this.attributes = {};

    console.log(metadata);
    this.populateMetadata();
  }

  private populateAttributes() {
    for (const attribute of this.monkeyData?.attributes ?? []) {
      this.attributes[attribute.trait_type] = attribute.value;
    }
  }

  private async populateMetadata() {
    let data: MonkeyData | undefined = undefined;
    const cachedData = localStorage.getItem(this.metadata.data.data.uri);
    if (cachedData) {
      console.log(`Monkey data was found in cache`)
      data = JSON.parse(cachedData);
    } else {
      console.log(`Monkey data was not found in cache - fetching`)
      do {
        try {
          const res = await axios.get(this.metadata.data.data.uri);
          data = res.data;
        } catch (e) {
          console.error(e)
          await sleep(1500);
        }
      } while (data === undefined);

      localStorage.setItem(this.metadata.data.data.uri, JSON.stringify(data));
    }
    this.monkeyData = data;
    this.populateAttributes();

    this.ready.resolve(true);
  }

  get name() {
    return this.attributes["Monkey Name"];
  }

  get collection() {
    return this.monkeyData?.collection?.name;
  }

  get imageUrl() {
    return (this.monkeyData?.properties.files?.length ?? 0) > 1 ? this.monkeyData?.properties.files[1].uri : null;
  }

  get fullBodyImageUrl() {
    return (this.monkeyData?.properties.files?.length ?? 0) > 2 ? this.monkeyData?.properties.files[2].uri : null;
  }

  get galleryImages() {
    // TODO: use gallery from updated JSON
    return [this.imageUrl ?? "", this.fullBodyImageUrl ?? ""];
  }

  get id() {
    return this.attributes["Monkey ID"];
  }

  get midfielderTraits(): Trait[] {
    return ["Flank Pass", "Extreme Pressing", "Pass Interception"].map(
      (name) => ({ name, value: this.attributes[name] })
    );
  }

  get strikerTraits(): Trait[] {
    return ["Precision Shot", "Protective Holding", "Hard Header"].map(
      (name) => ({ name, value: this.attributes[name] })
    );
  }

  get defenderTraits(): Trait[] {
    return ["Hard Tackle", "Defensive Awareness", "Last Stand Defense"].map(
      (name) => ({ name, value: this.attributes[name] })
    );
  }

  get goalkeeperTraits(): Trait[] {
    return ["Golden Gloves", "Goalie Resistance", "Goalie Long Ball"].map(
      (name) => ({ name, value: this.attributes[name] })
    );
  }

  get info() {
    return [
      {
        id: "1",
        name: "Midfielder",
        value: `+${Math.round(
          this.midfielderTraits.reduce(
            (sum: number, trait: Trait) => sum + parseFloat(trait.value),
            0
          ) * 100
        )}%`,
        details: this.midfielderTraits,
      },
      {
        id: "2",
        name: "Striker",
        value: `+${Math.round(
          this.strikerTraits.reduce(
            (sum: number, trait: Trait) => sum + parseFloat(trait.value),
            0
          ) * 100
        )}%`,
        details: this.strikerTraits,
      },
      {
        id: "3",
        name: "Defender",
        value: `+${Math.round(
          this.defenderTraits.reduce(
            (sum: number, trait: Trait) => sum + parseFloat(trait.value),
            0
          ) * 100
        )}%`,
        details: this.defenderTraits,
      },
      {
        id: "4",
        name: "Goalkeeper",
        value: `+${Math.round(
          this.goalkeeperTraits.reduce(
            (sum: number, trait: Trait) => sum + parseFloat(trait.value),
            0
          ) * 100
        )}%`,
        details: this.goalkeeperTraits,
      },
    ];
  }

  get stats() {
    return [
      { name: "Accuracy", max: "Max Accuracy" },
      { name: "Passing", max: "Max Passing" },
      { name: "Defense", max: "Max Defense" },
      { name: "Control", max: "Max Control" },
    ].map((stat) => ({
      type: stat.name,
      current: this.attributes[stat.name],
      max: this.attributes[stat.max],
    }));
  }

  get alphaScore() {
    return this.attributes["Alpha Score"];
  }

  get appearance() {
    return [
      "Color",
      "Tattoo",
      "Hairstyle",
      "Eyes",
      "Mouth",
      "Nose",
      "Ears",
      "Arms",
      "Legs",
      "Outfit",
      "Pattern Style",
    ].map((trait) => ({ name: trait, value: this.attributes[trait] }));
  }

  get birthday() {
    return moment(this.attributes["Monkey Date of Birth"]).format(
      "MMMM Do, YYYY"
    );
  }

  get details() {
    return [
        <span>Mint Address: <a style={{color: "white"}} target="_blank" href={`https://solscan.io/token/${this.mint}`} rel="noreferrer">{this.mint}</a></span>,
        <span>Token Account: <a style={{color: "white"}} target="_blank" href={`https://solscan.io/account/${this.publicKey.toString()}`} rel="noreferrer">{this.publicKey.toString()}</a></span>
    ];
  }

  async calcType() {
    await this.ready.promise;
    const workbook = await XSLX.read(new Buffer(PositionXLSX, 'base64'), { type: 'buffer' });

    const sheet = workbook.Sheets.Sheet1;
    // Max Attributes
    sheet.B5.v = parseInt(this.attributes['Max Accuracy'])
    sheet.B6.v = parseInt(this.attributes['Max Passing'])
    sheet.B7.v = parseInt(this.attributes['Max Defense'])
    sheet.B8.v = parseInt(this.attributes['Max Control'])
    // Perks
    sheet.B10.v = parseFloat(this.attributes['Precision Shot'])
    sheet.B11.v = parseFloat(this.attributes['Protective Holding'])
    sheet.B12.v = parseFloat(this.attributes['Hard Header'])
    sheet.B13.v = parseFloat(this.attributes['Flank Pass'])
    sheet.B14.v = parseFloat(this.attributes['Extreme Pressing'])
    sheet.B15.v = parseFloat(this.attributes['Pass Interception'])
    sheet.B16.v = parseFloat(this.attributes['Hard Tackle'])
    sheet.B17.v = parseFloat(this.attributes['Defensive Awareness'])
    sheet.B18.v = parseFloat(this.attributes['Last Stand Defense'])
    sheet.B19.v = parseFloat(this.attributes['Golden Gloves'])
    sheet.B20.v = parseFloat(this.attributes['Goalie Resistance'])
    sheet.B21.v = parseFloat(this.attributes['Goalie Long Ball'])

    Object.keys(sheet).forEach(key => {
      if (sheet[key]?.f?.toUpperCase) {
        sheet[key].f = sheet[key].f.toUpperCase()
      }
    })

    let type;
    try {
      XLSX_CALC(workbook);
      const xlsxType = workbook.Sheets.Sheet1.B1;
      type = xlsxType.v.toLowerCase();
    } catch (e) {
      console.error(e);
    }
    return type;
  }
}
