import Icon from "../../assets/images/landing/arrow.svg";

interface MonkeyTokenDetailsProps {
  details?: (string | JSX.Element)[];
}

const MonkeyTokenDetails = ({ details }: MonkeyTokenDetailsProps) => {
  const detailsArr = () => {
    let detailsNew : any = [];

    details?.forEach((detail : (any)) => {
      const children = detail.props.children;
      const title = children[0];
      const value = children[1];
      const valueText = value.props.children;
      const valueLink = value.props.href;

      detailsNew = [...detailsNew, ...[
        {title: title, text: valueText, link: valueLink},
      ]];
    });

    return detailsNew;
  }
  
  return (
    <div className="lp-accordion-item-wrap">
      <div className="lp-accordion-top">
        <div className="lp-accordion-top-name">Token Details</div>
        <div className="lp-accordion-top-info">
          <span className="arrow-wrap">
            <img src={Icon} alt="" />
          </span>
        </div>
      </div>
      <div className="lp-accordion-content lp-accordion-token-details">
        {/* {Array.from((details ?? []).entries()).map(([index, detail]) => (
          <div key={index}>{detail}</div>
        ))} */}
        {detailsArr().map((detail:any) => {
          return (
            <div className="token-details-row" key={detail.title} >
              <span className="detail-row-title">{detail.title}</span>
              <a href={detail.link} target="_blank" rel="noreferrer" className="detail-row-value">{detail.text}</a>
            </div>
          )
        })}
        
      </div>
    </div>
  );
};

export default MonkeyTokenDetails;
