type BaseItem = {
  item: {
    id: string;
    title: string;
    content: string[];
  };
  active: string;
  clickHandler: (id: string) => void;
};

const FaqsItem = ({ item, active, clickHandler }: BaseItem) => {
  return (
    <div
      className={`accordion-item ${
        active !== "0" && item.id === active ? "active" : ""
      }`}
      onClick={() => clickHandler(item.id)}
    >
      <div className="accordion-title-wrap">
        <div className="accordion-icon">
          {item.id === active ? " - " : " + "}
        </div>
        <div className="accordion-title">{item.title}</div>
      </div>
      <div className="accordion-content">
        {item.content.map((el, index) => {
          return <p key={index}>{el}</p>;
        })}
      </div>
    </div>
  );
};

export default FaqsItem;
