type BaseTitle  = {
    item: {
        text: string;
        color: string;
    }
}

const Title = ({item} : BaseTitle) => {
    return (
        <h2 className='section-title' style={{color: `${item.color}`}}>{item.text}</h2>
    )
}

export default Title