type BaseRoad = {
  item: {
    id: number;
    title: string;
    text: string;
    active?: boolean;
  };
};
const RoadmapItem = ({ item }: BaseRoad) => {
  return (
    <div className="roadmap-item">
      <div className={`roadmap-item-number ${item.active ? "active" : ""}`}>
        <div className="number-pulse"></div>
        <div className="number-pulse pulse-large"></div>
        <div className="number">{item.id}</div>
      </div>
      <div className="roadmap-item-content">
        <div className="roadmap-title">{item.title}</div>
        <div className="roadmap-text">{item.text}</div>
      </div>
    </div>
  );
};

export default RoadmapItem;
