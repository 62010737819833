import React from 'react';

type BaseBtn = {
  item: {
    text: string;
    bg: string;
    icon: string | null;
    border: number;
    transformButton?: string;
    transformText?: string;
    clickHandler?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
  };
};

const Button = ({ item }: BaseBtn) => {
  return (
    <button
      className={`btn btn-bg-${item.bg}`}
      onClick={(e) => (item.clickHandler ? item.clickHandler(e) : null)}
      style={{
        borderRadius: `${item.border}px`,
        transform: item.transformButton,
      }}
      disabled={item.disabled}
    >
      <span style={{ transform: item.transformText }}>
        {item.icon && <span className={`icon-${item.icon}`}></span>}
        {item.text}
      </span>
    </button>
  );
};

export default Button;
