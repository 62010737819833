import { useState } from "react";

import Title from "../Title";
import Image from "../../assets/images/game.png";
import Mobile from "../../assets/images/game-mobile.png";

const Game = () => {
  const [showMore, setShowMore] = useState(false);
  const title = {
    text: "The Game",
    color: "#FC0084",
  };

  return (
    <div id="game" className="game">
      <div className="game-container container">
        <div className="game-inner game-media">
          <img src={Image} alt="the game" />
          <div className="game-media-line"></div>
        </div>
        <div className="game-inner game-media game-media-mobile">
          <img src={Mobile} alt="the game" />
        </div>
        <div className="game-inner game-content">
          <Title item={title} />
          <div className="game-subtitle">
            MonkeyLeague is the next-gen esports game that empowers players to:
          </div>
          <div className="game-statement">CREATE – PLAY – COMPETE – EARN</div>
          <div className="game-text">
            MonkeyLeague combines high-production value, multiplayer gaming with
            Solana blockchain, NFTs, and decentralized finance to deliver an
            exciting, turn-based, play-and-earn soccer game that’s easy to learn
            yet hard to master.
          </div>
          <div className="game-text">
            Create your ultimate team of 4 Monkeys and play against the environment or other players in matches that last for twelve rounds, six per half.
          </div>

          <div className={`game-modes ${showMore ? "mobile-active" : ""}`}>
            <div className="game-modes-wrap">
              <div className="game-modes-title">Play in Three Modes:</div>
              <div className="game-modes-list">
                <div>
                  <span>Player vs Environment</span> - play matches against the environment to improve your gameplay
                </div>
                <div>
                  <span>Player vs Player</span> - a classic game where each
                  player control his entire team
                </div>
                <div>
                  <span>Team vs Team</span> - a game in which each team is being
                  played by multiple players
                </div>
              </div>
            </div>
          </div>
          <div className="read-more" onClick={() => setShowMore(!showMore)}>
            <span>Read More</span>
          </div>
          <div className="game-link-wrap">
            <div className="button-transformed">
              <a
                href={process.env.REACT_APP_DISCORD}
                className="game-link"
                target="_blank"
                rel="noreferrer"
              >
                Join DISCORD
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Game;
