type BaseTooltip = {
  item: {
    name: string;
    value: string;
    details: {
      name: string;
      value: string;
    }[]
  }
}

const Tooltip = ({ item }: BaseTooltip) => {
  return (
    <div className='tooltip' role='tooltip'>
      <div className='tooltip-arrow'></div>
      <div className='tooltip-content'>
        <div className='tooltip-top'>
          <span className='tooltip-name'>{item.name}</span>
          <span className='tooltip-value'>{item.value}</span>
        </div>
        <div className='tooltip-inner'>
          {item.details.map((item, index) => {
            return (
              <div key={index} className='tooltip-row'>
                <div className='tooltip-name'>{item.name}</div>
                <div className='tooltip-value'>{Math.round((parseFloat(item.value) ?? 0) * 100)}%</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Tooltip
