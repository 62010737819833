import { createContext, useContext } from "react";
import { PlayerConsent } from "components/gdpr/ConsentContext";

export interface AnalyticsDict {
    // eslint-disable-next-line
    [key: string]: any;
}

export type AnalyticsContextType = {
    init: (consent: PlayerConsent) => void;
    track: (action: string, props?: AnalyticsDict) => void;
    event: (name: string, params?: any) => void;
};

export const AnalyticsContext = createContext<AnalyticsContextType>(
    {} as AnalyticsContextType
);

export function useAnalytics() {
    return useContext(AnalyticsContext);
}
