import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";

// React components
import MonkeysTeamSlider from "../components/landing/MonkeysTeamSlider";
import Heading from "../components/Heading";
import Loader from "../components/Loader";

// Services
import { MonkeyLeagueService } from "../services/monkey-league-service";
import { Monkey } from "../models/monkey";

// Debug
const DEBUG = false;

// Heading defaults
const heading = {
    title: {
        color: "#fff"
    },
    subtitle: {
        boldText: ""
    }
};

function MonkeyPage(props: any) {
    const { monkeyList, title, subtitle } = props;
    const iconsClassName = `${(monkeyList.length > 1) ? "multi-icons" : "single-icons"}`;

    if (DEBUG) console.log("The monkey list is");
    if (DEBUG) console.log(monkeyList);

    return (
        <div className={`lp-team ${iconsClassName}`}>
            <Heading item={{
                title: {
                ...heading.title,
                text: title
                },
                subtitle: {
                ...heading.subtitle,
                text: subtitle
                }
            }} />
            <div className={`lp-monkeys-team ${iconsClassName}`}>
                <MonkeysTeamSlider monkeys={monkeyList} />
            </div>
        </div>
    );
};

function Team()
{
    // React router
    const navigate = useNavigate();

    // Solana variables
    const { connected, publicKey } = useWallet();
    const { connection } = useConnection();

    // State management
    const [loading, setLoading] = useState(true);
    const [monkeyList, setMonkeyList] = useState<any>([]);
    const [title, setTitle] = useState('Your monkey team')
    const [subtitle, setSubtitle] = useState('Select a monkey to view full profile')

    const loadMonkeys = useCallback(async () => {
        if (publicKey) {
            const monkeyLeagueService = new MonkeyLeagueService(connection);

            if (DEBUG) console.log("Requesting getMonkeys using Service");
            const _monkeyList = await monkeyLeagueService.getMonkeys(publicKey);
            if (DEBUG) console.log(_monkeyList);
            
            setMonkeyList(_monkeyList);

            await Promise.all(_monkeyList.map(monkey => monkey.ready.promise))

            if (_monkeyList.length === 0) {
                setTitle('You have no monkeys in your wallet')
                setSubtitle('')
            }
            else {
                setTitle('Your monkey team')
                setSubtitle('Select a monkey to view full profile')
            }

            setLoading(false);
        }
    }, [connection, publicKey]);

    useEffect(() => {
        loadMonkeys();
        if (!connected) {
            navigate("/connect");
        }
    }, [connected, loadMonkeys]);

    return (
        <div className="container">
            { loading
                ? <Loader />
                : <MonkeyPage monkeyList={monkeyList} title={title} subtitle={subtitle} />
            }
        </div>
    );
};

export default Team;
