import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";

import Heading from "../components/Heading";
import Loader from "../components/Loader";
import WalletButton from "../components/WalletButton";
import { MonkeyLeagueService } from "../services/monkey-league-service";

export interface ConnectParams {
    redirect?: string;
}

const heading = {
    title: {
        text: "Connect your wallet",
        color: "#fff"
    },
    subtitle: {
        text: "",
        boldText: ""
    }
};

function Connect({ redirect }: ConnectParams)
{
    const navigate = useNavigate();
    const { publicKey, ready, connecting } = useWallet();
    const { connection } = useConnection();
    let didNavigate = useRef<boolean>(false);
    const [checkingMonkeys, setCheckingMonkeys] = useState(false);

    const checkMonkeys = useCallback(async () => {
        if (publicKey && !didNavigate.current) {
            didNavigate.current = true;
            navigate(redirect ?? "/team");

            // console.log('checking monkeys!')
            // setCheckingMonkeys(true);
            // const monkeyLeagueService = new MonkeyLeagueService(connection);

            // const hasMonkeys = await monkeyLeagueService.hasMonkeys(publicKey);
            // didNavigate.current = true;
            // if (hasMonkeys) {
            //   navigate('/drop/team');
            // } else {
            //   navigate('/drop/select');
            // }
            }
    }, [navigate, publicKey, redirect]);

    useEffect(() => {
        checkMonkeys();
    }, [checkMonkeys]);

    const showLoader = () => <Loader />;

    const showConnectButton = () => {
        return (
            <>
                <Heading item={heading} />
                <div className="connect-info-wrap">
                    <WalletButton />
                </div>
            </>
        );
    };

    return (
        <div className="container">
            <div className="connect">
                {!connecting && !checkingMonkeys ? showConnectButton() : showLoader()}
            </div>
        </div>
    );
};

export default Connect;
