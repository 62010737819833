import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import SwiperCore, { Navigation } from 'swiper';

import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import { useState } from 'react';

import noMonkey from '../../assets/images/blank.png';

SwiperCore.use([Navigation]);

type BaseImage = {
  images: string[];
};

const SliderImage = ({ image }: any) => {
  const [error, setError] = useState(false);

  return (
    <>
      {!error && <img src={image} onError={() => setError(true)} alt="" />}
      {error && (
        <div className="invalid-monkey-img">
          <img src={noMonkey} />
          <h3>
            Image not
            <br />
            Found
          </h3>
        </div>
      )}
    </>
  );
};

const CardSlider = ({ images }: BaseImage) => {
  return (
    <Swiper navigation={true} loop={true}>
      {images.map((image, index) => {
        return (
          <SwiperSlide key={index}>
            {image && <SliderImage image={image} />}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default CardSlider;
