import { Connection, programs } from "@metaplex/js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import { Monkey } from "../models/monkey";

const { metadata: { Metadata } } = programs;

const SYMBOL_LIST = JSON.parse(process.env.REACT_APP_MONKEY_SYMBOL_LIST ?? "[]");

export class MonkeyLeagueService
{
    connection: Connection;

    constructor(connection: Connection) {
        this.connection = connection;
    }

    async monkeyMetadata(mintAddress: string) {
        let meta = undefined;
        const cachedData = localStorage.getItem(mintAddress);
        if (cachedData) {
            console.log(`Monkey meta data was found in cache`);
            meta = JSON.parse(cachedData);
        }
        else {
            console.log(`Monkey meta data was not found in cache - fetching`);
            const pda = await Metadata.getPDA(mintAddress);
            meta = await Metadata.load(this.connection, pda);
            
            localStorage.setItem(mintAddress, JSON.stringify(meta));
        }

        return meta!;
    }

    async getPublicMonkey(mint: string) {
        const meta = await this.monkeyMetadata(mint);
        const monkey = new Monkey(new PublicKey(mint), meta);
        return monkey;
    }

    async getMonkey(publicKey: PublicKey, monkeyAddress: string): Promise<Monkey | undefined> {
        const monkeyList = await this.getMonkeys(publicKey);

        return monkeyList.find(
            (monkey) => monkey.publicKey.toString() === monkeyAddress
        );
    }

    async getMonkeys(publicKey: PublicKey): Promise<Monkey[]> {
        let monkeyList: Monkey[] = [];
        if (publicKey)
        {
            const context = await this.connection.getParsedTokenAccountsByOwner(
                publicKey,
                { programId: new PublicKey(TOKEN_PROGRAM_ID) }
            );
            const monkeyPromiseList: Promise<Monkey | null>[] = [];

            for (const account of context.value)
            {
                monkeyPromiseList.push(new Promise(async (resolve) => {
                    try {
                        console.log(`Checking account: ${account.pubkey.toString()}, mint: ${account.account.data.parsed.info.mint}`);
                        const meta = await this.monkeyMetadata(
                            account.account.data.parsed.info.mint
                        );
                        
                        if (parseInt(account.account.data.parsed.info.tokenAmount.uiAmount) > 0
                        && SYMBOL_LIST.indexOf(meta.data.data.symbol) > -1) {
                            console.log(
                                `Adding monkeys balance: ${account.account.data.parsed.info.tokenAmount.uiAmount}, symbol: ${meta.data.data.symbol}`
                            );
                            const monkey = new Monkey(account.pubkey, meta, account.account);
                            resolve(monkey);
                        } else {
                            console.log(
                                `Monkey does not belong. balance: ${account.account.data.parsed.info.tokenAmount.uiAmount}, symbol: ${meta.data.data.symbol}`
                            );
                            resolve(null);
                        }
                    }
                    catch (e) {
                        console.log(
                            `Error trying to get metadata for account ${account.pubkey.toString()}`
                        );
                        resolve(null);
                    }
                }));
            }
            
            monkeyList = await (await Promise.all(monkeyPromiseList)).filter(maybeMonkey => maybeMonkey instanceof Monkey) as Monkey[];
        }

        return monkeyList;
    }

    async hasMonkeys(publicKey: PublicKey): Promise<boolean> {
        if (publicKey) {
            console.log(publicKey.toString());
            const monkeyList = await this.getMonkeys(publicKey);
            return monkeyList.length > 0;
        }
        return false;
    }
}
