import { useState, useRef } from "react";
import { Link } from 'react-router-dom';
import { SocialLinks } from "components/Social";

import axios from 'axios';

function Footer() {
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const emailRef = useRef<HTMLInputElement>(null);

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();

        // data to send to the server - email
        axios.get(`${process.env.REACT_APP_SEND_NEWSLETTER}?mail=${email}`);

        setEmail('');
        setSuccess(true);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    return (
        <footer className="monkey-footer">
            <div className="footer-top">
                <div className="footer-subscribe">SUBSCRIBE FOR THE LATEST UPDATES</div>
                <div className="footer-form-wrap">
                    <form className="footer-form" onSubmit={submitHandler}>
                        <div className="form-content">
                            <input
                                className="email"
                                type="email"
                                name="email"
                                onChange={onChange}
                                value={email}
                                required
                                placeholder="Email"
                            />
                            { success && (
                                <span className="footer-success">
                                    The form was submitted successfully
                                </span>
                            ) }
                        </div>
                        <div className="form-btn-wrap">
                            <input className="form-btn" type="submit" value="Submit" />
                        </div>
                    </form>
                </div>

                <div className="footer-social">
                    <SocialLinks />
                </div>
            </div>

            <div className="footer-info">
                <span>© 2022. All Rights Reserved MonkeyLeague®.</span>
                <Link to="/legal-pack" className="footer-link">Legal Pack</Link>
            </div>
        </footer>
    );
};

export default Footer;
