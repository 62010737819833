import Button from "./Button";
import Loader from "./Loader";

type BaseHeading = {
  item: {
    title: {
      text: string;
    };
    subtitle: {
      text: string;
      boldText?: string;
      color?: String;
      error?: Boolean;
    };
  };
  action?: {
    handler: (e: React.MouseEvent<HTMLButtonElement>) => void;
    label: string;
  };
  showLoader?: Boolean;
};

const Heading = ({ item, action, showLoader = false }: BaseHeading) => {
  const button = {
    text: action?.label || "Cancel",
    bg: "mint-action",
    icon: null,
    border: 25,
    transform: "",
    clickHandler: action?.handler,
  };

  return (
    <div className="heading">
      <h1 className={`title${item.subtitle.error ? " error" : ""}`}>
        {item.title.text}
      </h1>
      {showLoader && <Loader />}
      {item.subtitle.text ? (
        <div className={`subtitle${item.subtitle.error ? " error" : ""}`}>
          {item.subtitle.text}
          {item.subtitle.boldText ? (
            <span>{item.subtitle.boldText}</span>
          ) : null}
        </div>
      ) : null}
      {action && (
        <div className="heading-action">
          <Button item={button} />
        </div>
      )}
    </div>
  );
};

export default Heading;
