import CloseButton from "./CloseButton";

type Props = {
    closeHandler: () => void;
};

function PopupCloseButton({ closeHandler }: Props) {
    return (
        <div className="popup-close-button">
            <div className="popup-close">
                <CloseButton onClick={closeHandler} />
            </div>
        </div>
    );
}

export default PopupCloseButton;
