import { useState } from "react";

const isBrowser = (typeof window !== "undefined");

interface IOptions {
    [key: string]: any
}

export function stringifyOptions(options: IOptions) {
    return Object.keys(options).reduce((acc, key) => {
            if (key === "days") {
                return acc;
            }
            else {
                if (options[key] === false) {
                    return acc;
                } 
                else if (options[key] === true) {
                    return `${acc}; ${key}`;
                }
                else {
                    return `${acc}; ${key}=${options[key]}`;
                }
            }
    }, "");
}

export function setCookie<T = string>(name: string, value: T, options?: IOptions) {
    if (!isBrowser) return;

    const optionsWithDefaults = { days: 7, path: "/", ...options };
    const expires = new Date(Date.now() + optionsWithDefaults.days * 864e5).toUTCString();
    const strValue = (typeof value === "string") ? value : JSON.stringify(value);

    document.cookie = `${name}=${encodeURIComponent(strValue)}; expires=${expires}` +
        stringifyOptions(optionsWithDefaults);
};

export function getCookies() {
    return (isBrowser) ? document.cookie : [];
};

export function getCookie<T = string>(name: string, initialValue: T): T {
    if (isBrowser) {
        try {
            const value: T = JSON.parse(
                document.cookie.split("; ").reduce((r, v) => {
                    const parts = v.split("=");
                    return (parts[0] === name) ? decodeURIComponent(parts[1]) : r;
                }, "")
            );
            return value ?? initialValue;
        }
        catch (error) {
            return initialValue;
        }
    }

    return initialValue;
};

export function useCookie<T = string>(name: string, initialValue: T) {
    const defaultValue = (typeof initialValue === "function")
        ? initialValue() : initialValue;

    const [item, setItem] = useState(getCookie(name, defaultValue));

    const updateItem = (value: T, options?: IOptions) => {
        setItem(value);
        setCookie(name, value, options);
    };

    return [item, updateItem];
}

// Some ideas were taken from:
// - https://github.com/carhartl/typescript-cookie