import { useState, useRef, useEffect, MouseEvent } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { Link } from "react-router-dom";
import { SocialLinks } from "components/Social";

import WalletButton from "components/WalletButton";
import Logo from "assets/images/monkey-league-logo.png";

require("@solana/wallet-adapter-react-ui/styles.css");


// Whether to display debug messages
const DEBUG = false;

// Whether to display the "Launch App" Button
const SHOW_LAUNCH_APP_BUTTON = true;
const SHOW_SOCIAL_LINKS = false;

// Home website domains (the links will be opened in the same tab)
const HOME_DOMAINS = [
    "www.monkeyleague.io",
    "myteam.monkeyleague.io",
    "dev.myteam.monkeyleague.io",
    "dev.monkeyleague.io",
    "monkeyleague.io",
    "www.monkeyball.com",
    "dev.monkeyball.com",
    "monkeyball.com",
    "monkey-home.herokuapp.com",
    "monkey-team.herokuapp.com",
    "link.redgiant.com",
    "localhost"
];

// Meet your monkeys menu constants
const showMeetYourMonkeysEnv = process.env.REACT_APP_MENU_SHOW_MEET_YOUR_MONKEYS;
const showMeetYourMonkeys = false;//(showMeetYourMonkeysEnv ?? "").toString().toLowerCase() === "true";

// Verify monkey menu constants
const showVerifyEnv = process.env.REACT_APP_MENU_SHOW_VERIFY
const showVerify = (showVerifyEnv ?? "").toString().toLowerCase() === "true";


export interface ChildItem {
    id: string;
    title: string;
    path: string;
    enabled?: boolean;
}

export interface BaseHeader {
    id: string;
    title: string;
    path?: string;
    enabled?: boolean;
    showDropDown?: boolean;
    setShowDropDown?: (val: boolean) => void;
    children?: ChildItem[];
}

// Top level menu
//
const menu: BaseHeader[] = [
    {
        id: "1",
        title: "Home",
        path: process.env.REACT_APP_MENU_HOME ?? "/"
    },
    {
        id: "2",
        title: "Overview",
        path: process.env.REACT_APP_MENU_OVERVIEW ?? "#game"
    },
    {
        id: "3",
        title: "Token Info",
        enabled: true,
        children: [
            {
                id: "31",
                title: "Solana Explorer",
                path: process.env.REACT_APP_MENU_SOLANA_EXPLORER ?? ""
            },
            {
                id: "32",
                title: "Solscan",
                path: process.env.REACT_APP_MENU_SOLSCAN ?? ""
            },
            {
                id: "33",
                title: "Coinmarketcap",
                path: process.env.REACT_APP_MENU_COIN_MARKET_CAP ?? ""
            },
            {
                id: "34",
                title: "Coingecko",
                path: process.env.REACT_APP_MENU_COIN_GEKO ?? ""
            },
            {
                id: "35",
                title: "Audit disclaimer",
                path: process.env.REACT_APP_MENU_AUDIT_DISCLAIMER ?? ""
            },
            {
                id: "36",
                title: "Whitepaper",
                path: process.env.REACT_APP_MENU_WHITEPAPER ?? ""
            }
        ],
    },
    {
        id: "4",
        title: "Buy $MBS",
        enabled: true,
        children: [
            {
                id: "41",
                title: "Contract Address",
                path: process.env.REACT_APP_MENU_CONTRACT_ADDRESS ?? ""
            },
            {
                id: "42",
                title: "FTX",
                path: process.env.REACT_APP_MENU_FTX ?? ""
            },
            {
                id: "43",
                title: "GATE.IO",
                path: process.env.REACT_APP_MENU_GATE_IO ?? ""
            },
            {
                id: "44",
                title: "BYBIT",
                path: process.env.REACT_APP_MENU_BYBIT ?? ""
            },
            {
                id: "45",
                title: "RAYDIUM TRADING",
                path: process.env.REACT_APP_MENU_RAYDIUM_TRADING ?? ""
            },
            {
                id: "46",
                title: "PRISM SWAP",
                path: process.env.REACT_APP_MENU_PRISM_SWAP ?? ""
            }
        ]
    },
    {
        id: "5",
        title: "Roadmap",
        path: process.env.REACT_APP_MENU_ROADMAP ?? "#roadmap"
    },
    {
        id: "6",
        title: "Faqs",
        path: process.env.REACT_APP_MENU_FAQ ?? "#faqs"
    },
    {
        id: "7",
        title: "Meet your monkeys",
        enabled: showMeetYourMonkeys,
        children: [
            // {
            //     id: "71",
            //     title: "Meet your monkeys",
            //     enabled: showMeetYourMonkeys,
            //     path: process.env.REACT_APP_MENU_MEET_YOUR_MONKEYS ?? ""
            // },
            {
                id: "72",
                title: "Verify monkey",
                enabled: showVerify,
                path: process.env.REACT_APP_MENU_VERIFY ?? "/verify"
            }
        ]
    }
];


// Link item path types
//
enum PathType {
    Undefined = "undefined",
    Absolute  = "absolute",
    Relative  = "relative",
    RelativeWithHash = "relativeWithHash"
}

// Link item properties
//
type LinkItemProps = {
    item: BaseHeader | ChildItem,
    onClick: (event: MouseEvent) => void
}

function LinkItem({ item, onClick }: LinkItemProps)
{
    let pathType: PathType = PathType.Undefined;

    if (item.path?.startsWith("http")) {
        pathType = PathType.Absolute;
    }
    else if (item.path?.startsWith("#") || item.path?.startsWith("/#")) {
        pathType = PathType.RelativeWithHash;
    }
    else {
        pathType = PathType.Relative;
    }

    if (DEBUG) console.log(`Link item with id=${item.id} path=${item.path} has pathType=${pathType}`);

    let linkElement;
    let url: URL | undefined;

    try {
        url = new URL(item.path ?? "/", window.location.origin);
    }
    catch (e) {
        console.error(`Unable to process item's path ${item.path}`);
    }

    switch (pathType)
    {
        case PathType.Absolute: {
            const shouldOpenInTheSameTab =
                (url?.hostname) ? HOME_DOMAINS.includes(url.hostname) : true;

            linkElement = (
                <a
                    className="link"
                    href={item.path}
                    target={shouldOpenInTheSameTab ? undefined : "_blank"}
                    rel="noreferrer"
                    onClick={onClick}
                >
                    <span>{ item.title }</span>
                </a>
            );
            break;
        }

        case PathType.Relative: {
            linkElement = (
                <Link
                    className="link"
                    to={item.path ?? ""}
                    onClick={onClick}
                >
                    <span>{ item.title }</span>
                </Link>
            );
            break;
        }

        case PathType.RelativeWithHash:
        default: {
            linkElement = (
                <a
                    className="link"
                    href={`/${item.path}`}
                    rel="noreferrer"
                    onClick={onClick}
                >
                    <span>{ item.title }</span>
                </a>
            );
        }
    }

    return (item.enabled !== false) ? linkElement : null;
};


// Dropdown item properties
//
type DropdownItemProps = {
    item: BaseHeader;
    onClick: (event: MouseEvent) => void;
}

function DropdownItem({ item, onClick }: DropdownItemProps)
{
    if (DEBUG) console.log(`DropdownItem item with id=${item.id}`);

    const [showDropDown, setShowDropDown] = useState(false);

    return (item.enabled !== false) ? (
        // dropdown menu
        <div
            className={`link has-children ${showDropDown ? "active" : ""}`}
            onClick={() =>
                setShowDropDown &&
                setShowDropDown(!showDropDown)
            }
        >
            <span>{ item.title }</span>
            <div
                className="has-children-arrow"
                onClick={() =>
                    setShowDropDown &&
                    setShowDropDown(!showDropDown)
                }
            ></div>
            <div className={`sub-menu ${showDropDown ? "active" : ""}`} >
                { item.children?.map((child) => <LinkItem key={child.id} item={child} onClick={onClick} /> ) }
            </div>
        </div>
    ) : null;
}


// Social links bar
//
type HeaderSocialLinksProps = {
    menu: BaseHeader[];
    showMobile: boolean;
}

function HeaderSocialLinks({ menu, showMobile } : HeaderSocialLinksProps) {
    return (
        <div className={`social ${menu ? "monkey-header-social" : ""} ${showMobile ? "mobile-active" : ""}`}>
            <SocialLinks />
        </div>
    );
}


// Launch App button
//
function LaunchAppButton()
{
    const launchAppURL = process.env.REACT_APP_MENU_LAUNCH_APP ?? "https://app.monkeyleague.io/store";

    return (
        <a className="launch-app"
           href={launchAppURL}
           target="_blank"
           rel="noreferrer"
        >
            <div className="launch-app-btn hide-on-tablet" />
        </a>
    );
}

function LaunchAppLink()
{
    const launchAppURL = process.env.REACT_APP_MENU_LAUNCH_APP ?? "https://app.monkeyleague.io/store";

    return (
        <a className="launch-app"
           href={launchAppURL}
           target="_blank"
           rel="noreferrer"
        >
            <div className="launch-app-btn show-on-tablet" />
        </a>
    );
}

function Header()
{
    const [showMobile, setShowMobile] = useState(false);
    const headerRef = useRef<HTMLDivElement>(null);

    const headerScroll = () => {
        if (window.scrollY > 1) {
            headerRef.current?.classList.add("sticky");
        }
        else {
            headerRef.current?.classList.remove("sticky");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", headerScroll);
        return () => {
            window.removeEventListener("scroll", headerScroll);
        }
    }, []);

    const { publicKey } = useWallet();

    const clickHandler = (event: MouseEvent) => {
        setShowMobile(false);
    }

    if (DEBUG) console.log(`Window location: ${window.location}`);

    return (
        <header className={`header ${showMobile ? "mobile-active" : ""}`} ref={headerRef}>

            <div className="header-logo">
                <a href={process.env.REACT_APP_MENU_HOME} className="logo">
                    {/* Source image size is 150 x 150 px */}
                    <img src={Logo} alt="MonkeyLeague" />
                </a>
                {/* <Link to="/" className="logo"> */}
                    {/* Source image size is 150 x 150 px */}
                    {/* <img src={Logo} alt="monkeyleague" /> */}
                {/* </Link> */}
            </div>

            { menu && (
                <nav className={`nav ${showMobile ? "mobile" : ""}`}>
                    { menu.map((item) => {
                        return (item.path)
                            ? <LinkItem key={item.id} item={item} onClick={clickHandler} /> // Plain item
                            : <DropdownItem key={item.id} item={item} onClick={clickHandler} /> // Dropdown menu item
                    })}
                    { SHOW_LAUNCH_APP_BUTTON && <LaunchAppLink /> }
                </nav>
            )}

            { publicKey && <WalletButton /> }
            { SHOW_LAUNCH_APP_BUTTON && <LaunchAppButton /> }
            { SHOW_SOCIAL_LINKS && <HeaderSocialLinks menu={menu} showMobile={showMobile} /> }

            <div className="header-hamburger">
                <div
                    className={`hamburger ${showMobile ? "active" : ""}`}
                    onClick={() => setShowMobile(!showMobile)}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </header>
    );
};

export default Header;
