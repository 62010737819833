export enum CloseButtonType {
    Popup = "Popup",
    Settings = "Settings",
}

type Props = {
    btnType?: CloseButtonType;
    onClick: () => void;
};

function CloseButton({ btnType, onClick }: Props)
{
    btnType = btnType ? btnType : CloseButtonType.Popup;

    return (
        <div
            className={`close-button ${
                btnType == CloseButtonType.Popup ? "bg-magenta" : ""
            }`}
            onClick={onClick}
        >
            <div className="close-button-cross">
                <svg
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-[64px] h-[64px]"
                >
                    <path
                        d="m42.665 25.695-6.363 6.362 6.363 6.366-4.242 4.242-6.364-6.365-6.364 6.365-4.243-4.242 6.364-6.366-6.364-6.362 4.243-4.243 6.364 6.363 6.364-6.363 4.242 4.243z"
                        fill="#D2CEFF"
                        fillRule="evenodd"
                        className={`${
                            btnType == CloseButtonType.Popup ? "fill-sunny" : "fill-[#D2CEFF]"
                        }`}
                    />
                </svg>
            </div>
        </div>
    );
}

export default CloseButton;
