import { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useConsent, PlayerConsent } from "components/gdpr/ConsentContext";

// Analytics Context
import { AnalyticsContext, AnalyticsDict } from "./AnalyticsContext";

// MixPanel Analytics
import mixpanel from "mixpanel-browser";

// Google Analytics
import ReactGA from "react-ga4";

// Analytics Tokens
import { AnalyticsTokens, AnalyticsMap } from "./AnalyticsTokens";

// Constants
const DEBUG = true;

// Report page navigations to GA
/*
function usePageViews() {
    const location = useLocation();
    useEffect(() => {
        const pagename = location.pathname + location.search + location.hash;
        ReactGA.send({
            hitType: "pageview",
            page: pagename
        });
    }, [location]);
}*/

interface AnalyticsProviderProps {
    children: React.ReactNode;
}

// function AnalyticsProvider(props: any)
function AnalyticsProvider({ children }: AnalyticsProviderProps)
{
    if (DEBUG) console.log(`>>> AnalyticsProvider: Invoked AnalyticsProvider`);

    const { consent, updated } = useConsent();
    const location = useLocation();

    const init = (consent: PlayerConsent) => {
        if (consent.analytics) {
            ReactGA.initialize(AnalyticsTokens.GA_MEASUREMENT_ID);

            // Note: Enabling the debug mode flag is useful during development,
            // but it is recommended to remove it in production mode
            mixpanel.init(AnalyticsTokens.MIXPANEL_TOKEN, { debug: false });

            // Report if ReactGA and MixPanel is initialized
            if (DEBUG) console.log(`>>> AnalyticsProvider: Initialized ReactGA and MixPanel`);
        }
    };

    useEffect(() => {
        init(consent);
    }, [consent]);

    useEffect(() => {
        if (consent.analytics && updated) {
            if (DEBUG) console.log(`>>> AnalyticsProvider: useEffect for track action with ${updated}`);
            track(AnalyticsMap.PLAYER_CONSENT, {
                // playerId: playerId,
                ...consent
            });
        }
    }, [consent, updated]);

    useEffect(() => {
        if (consent.analytics) {
            const args = {
                page_title: document.title,
                page_location: window.location.href,
                page_view: location.pathname + location.search + location.hash
            };

            // window.gtag("event", "page_view", args);
            ReactGA.event("page_view", args);

            /* ReactGA.send({
                hitType: "pageview",
                page: args.page_location
            }); */

            if (DEBUG) console.log(`>>> AnalyticsProvider: changed location to ${args.page_location}`);
            if (DEBUG) console.log(`>>> AnalyticsProvider: page_view args are ${JSON.stringify(args, null, 4)}`);
        }
    }, [consent, location]);

    const event = (name: string, params: any) => {
        if (consent.analytics) {
            ReactGA.event(name, params);

            if (DEBUG) console.log(`>>> AnalyticsProvider: event name is ${name}`);
            if (DEBUG) console.log(`>>> AnalyticsProvider: event params are ${JSON.stringify(params, null, 4)}`);
        }
    };

    const track = (action: string, props?: AnalyticsDict) => {
        if (DEBUG) console.log(`>>> AnalyticsProvider: track action is called with ${action}`);
        if (DEBUG) console.log(`>>> AnalyticsProvider: consent is ${JSON.stringify(consent)}`);
        if (consent.analytics) {
            if (action == AnalyticsMap.PLAYER_CONSENT) { mixpanel.track(action, props); }
            if (DEBUG) console.log(`>>> AnalyticsProvider: track action is ${action}`);
            if (DEBUG) console.log(`>>> AnalyticsProvider: track props are ${JSON.stringify(props, null, 4)}`);
        }
    };

    return (
        <AnalyticsContext.Provider value={{ init, track, event }}>
            { children }
        </AnalyticsContext.Provider>
    );
}

export default AnalyticsProvider;
