import { Link } from "react-router-dom";
import { Monkey } from "../../models/monkey";
import MonkeyCard from "../MonkeyCard";

import Slider from 'react-slick';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type BaseMonkeys = {
  monkeys: Monkey[];
};

const MonkeysTeamSlider = ({monkeys}: BaseMonkeys) => {
    let settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1240,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  infinite: false,
                  dots: false
                }
              },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                dots: false
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow:1 ,
                slidesToScroll: 1,
              }
            }
          ]
      };
    return (
        <Slider {...settings}>
            {monkeys.map(monkey => {
                return (
                    <Link
                        key={monkey.publicKey.toString()}
                        to={monkey.publicKey.toString()}
                        className="lp-team-link"
                    >
                        <MonkeyCard monkey={monkey} />
                    </Link>
            )
        })}
        </Slider>
    )
}

export default MonkeysTeamSlider;
